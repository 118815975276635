import config from "../config";
import { AppenData, axiosFunc } from "../actions/common";
import { Decryptdata, Encryptdata } from "../actions/encode_decode";
let token = sessionStorage.getItem("usertoken")
  ? sessionStorage.getItem("usertoken")
  : localStorage.getItem("usertoken");
// const sees = sessionStorage.getItem('usertoken')
console.log("tokentokentoken", token);

export const TokenUpdate = () => {
  token = sessionStorage.getItem("usertoken")
  ? sessionStorage.getItem("usertoken")
  : localStorage.getItem("usertoken");
}


export const GetRewardlist = async(data)=>{
  let senddata = {
    method: "get",
    url: `${config.BACK_URL}/user/rewardlist`,
    params: { data: Encryptdata(data) },
    headers: {
      contentype: "application/json",
      'authorization' : token
    },
  };
  console.log(senddata);
  let response = await axiosFunc(senddata);
  response.data = Decryptdata(response?.data);
  return response.data;
}

export const Claimreward = async(data)=>{
  let senddata = {
    method: "post",
    url: `${config.BACK_URL}/user/claimreward`,
    data: { data: Encryptdata(data) },
    headers: {
      contentype: "application/json",
      'authorization' : token
    },
  };
  console.log(senddata);
  let response = await axiosFunc(senddata);
  response.data = Decryptdata(response?.data);
  return response.data;
}

export const userregister = async (data) => {
  let senddata = {
    method: "post",
    url: `${config.BACK_URL}/user/register`,
    data: { data: Encryptdata(data) },
    headers: {
      contentype: "application/json",
    },
  };
  console.log(senddata);
  let response = await axiosFunc(senddata);
  response.data = Decryptdata(response?.data);
  return response.data;
};

export const verifyotp = async (data) => {
  let senddata = {
    method: "put",
    url: `${config.BACK_URL}/user/verifyotp`,
    data: { data: Encryptdata(data) },
    headers: {
      contentype: "application/json",
    },
  };
  console.log(senddata);
  let response = await axiosFunc(senddata);
  response.data = Decryptdata(response?.data);
  return response.data;
};

export const resendotp = async (data) => {
  let senddata = {
    method: "put",
    url: `${config.BACK_URL}/user/resendotp`,
    data: { data: Encryptdata(data) },
    headers: {
      contentype: "application/json",
    },
  };

  let response = await axiosFunc(senddata);
  response.data = Decryptdata(response?.data);
  return response.data;
};
export const loginfetch = async (data) => {
  let senddata = {
    method: "post",
    url: `${config.BACK_URL}/user/login`,
    data: { data: Encryptdata(data) },
    headers: {
      contentype: "application/json",
    },
  };

  let response = await axiosFunc(senddata);
  console.log("🚀 ~ loginfetch ~ response:", response)
  response.data = Decryptdata(response?.data);

  console.log("kkkkkkkkkkkk", response.data);
  return response.data;
};

export const Forgetpasswordfetch = async (data) => {
  let senddata = {
    method: "put",
    url: `${config.BACK_URL}/user/forgetpassword`,
    data: { data: Encryptdata(data) },
    headers: {
      contentype: "application/json",
    },
  };

  let response = await axiosFunc(senddata);
  response.data = Decryptdata(response?.data);

  console.log(response.data);
  return response.data;
};

export const newpasswordfetch = async (data) => {
  let senddata = {
    method: "put",
    url: `${config.BACK_URL}/user/newpassword`,
    data: { data: Encryptdata(data) },
    headers: {
      contentype: "application/json",
      'authorization' : token
    },
  };

  let response = await axiosFunc(senddata);
  response.data = Decryptdata(response?.data);

  console.log(response.data);
  return response.data;
};

export const fetchplanLists = async (data) => {
  try {
    let senddata = {
      method: "GET",
      url: `${config.ADMIN_URL}/planlists`,
      params :{ data: Encryptdata(data) }
    };
    let response = await axiosFunc(senddata);
    response.data = await Decryptdata(response?.data);
    console.log("errxxv", response.data);
    return response.data;
  } catch (err) {
    console.log("erssssssssssssr", err);
    return false;
  }
};

export const addsubcription = async (data) => {
  try {
    let senddata = {
      method: "post",
      url: `${config.BACK_URL}/subcription/Subscribe`,
      data: { data: Encryptdata(data) },
      headers: {
        contentype: "application/json",
        'authorization' : token
      },
    };
    let response = await axiosFunc(senddata);
    response.data = Decryptdata(response?.data);

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
export const fetchanticodeupdate = async (data) => {
  try {
    let senddata = {
      method: "put",
      url: `${config.BACK_URL}/user/updateanticode`,
      data: { data: Encryptdata(data) },
      headers: {
        contentype: "application/json",
        authorization: token,
      },
    };

    let response = await axiosFunc(senddata);
    response.data = Decryptdata(response?.data);

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchchangepassword = async (data) => {
  try {
    let senddata = {
      method: "put",
      url: `${config.BACK_URL}/user/changepassword`,
      data: { data: Encryptdata(data) },
      headers: {
        contentype: "application/json",
        authorization: token,
      },
    };

    let response = await axiosFunc(senddata);
    response.data = Decryptdata(response?.data);

    console.log("fetchchangepassword", response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchgettwofa = async () => {
  try {
    let senddata = {
      method: "get",
      url: `${config.BACK_URL}/user/gettwofa`,
      headers: {
        contentype: "application/json",
        authorization: token,
      },
    };

    let response = await axiosFunc(senddata);
    response.data = Decryptdata(response?.data);

    console.log("fetchgettwofa", response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchenabletwofacode = async (data) => {
  try {
    let senddata = {
      method: "put",
      url: `${config.BACK_URL}/user/enabletwofa`,
      data: { data: Encryptdata(data) },
      headers: {
        contentype: "application/json",
        authorization: token,
      },
    };

    let response = await axiosFunc(senddata);
    response.data = Decryptdata(response?.data);

    console.log("fetchenabletwofacode", response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchconnecttwofa = async (data) => {
  try {
    let senddata = {
      method: "post",
      url: `${config.BACK_URL}/user/connecttwofa`,
      data: { data: Encryptdata(data) },
      headers: {
        contentype: "application/json",
      },
    };

    let response = await axiosFunc(senddata);
    response.data = Decryptdata(response?.data);

    console.log("fetchconnecttwofa", response.data, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchreconnect = async () => {
  try {
    let senddata = {
      method: "get",
      url: `${config.BACK_URL}/user/reconnect`,
      headers: {
        contentype: "application/json",
        authorization: token,
      },
    };
    let response = await axiosFunc(senddata);

console.error("fetchanticodeupdate2",response)
if(!response.status){
  return response
}

    response.data = Decryptdata(response?.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchchangeemail = async (data) => {
  try {
    let senddata = {
      method: "put",
      url: `${config.BACK_URL}/user/changeemail`,
      data: { data: Encryptdata(data) },
      headers: {
        contentype: "application/json",
        authorization: token,
      },
    };

    let response = await axiosFunc(senddata);
    response.data = Decryptdata(response?.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


export const fetchverifyemailchange = async(data)=> {

    try {
        let senddata = {
          method: "put",
          url: `${config.BACK_URL}/user/veriftyotpchnageemail`,
          data: { data: Encryptdata(data) },
          headers: {
            contentype: "application/json",
            authorization: token,
          },
        };
    
        let response = await axiosFunc(senddata);
        response.data = Decryptdata(response?.data);
        return response.data;
      } catch (error) {
        console.error(error);
      }
}

export const fetchsendotphone = async ()=>{
    try {
        let senddata = {
          method: "get",
          url: `${config.BACK_URL}/user/sendotpphone`,
          headers: {
            contentype: "application/json",
            authorization: token,
          },
        };
    
        let response = await axiosFunc(senddata);
        response.data = Decryptdata(response?.data);
        return response.data;
      } catch (error) {
        console.error(error);
      }


}



export const fetchverifyotphone = async (data)=>{
    try {
        let senddata = {
          method: "put",
          url: `${config.BACK_URL}/user/verifyotpphone`,
          data: { data: Encryptdata(data) },
          headers: {
            contentype: "application/json",
            authorization: token,
          },
        };
    
        let response = await axiosFunc(senddata);
        response.data = Decryptdata(response?.data);
        return response.data;
      } catch (error) {
        console.error(error);
      }


}


export const fetchbinding = async (data)=>{

  try {
    let senddata = {
      method: "put",
      url: `${config.BACK_URL}/user/binding`,
      data: { data: Encryptdata(data) },
      headers: {
        contentype: "application/json",
        authorization: token,
      },
    };

    let response = await axiosFunc(senddata);
    response.data = Decryptdata(response?.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }

}
export const fetchuploadprofileimage = async(data)=>{
  let formdata = AppenData(data)
  try {
      let senddata = {
        method: "post",
        url: `${config.BACK_URL}/user/uploadimage`,
        data: formdata[0],
        headers: {
          contentype: 'multipart/form-data',
          authorization: token,
        },
      };
  
      let response = await axiosFunc(senddata);
      // console.log()
      response.data = Decryptdata(response?.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }

}
