import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/all.module.css'
import Grid from '@mui/material/Grid';
import Sidebar from '../common/Sidebar';
import { Col, Nav, Row, Tab, ButtonGroup, Button, Form } from 'react-bootstrap';
import Orderbooktable from '../components/orderbooktable'
import { useDispatch, useSelector } from 'react-redux';
import { getArbitrages, Getmarketdepth ,Getorderbook } from '../api/trade.axios';
// import { socket } from '../socket';
import { isEmpty } from '../actions/common';

function Orderbook() {
    const Arbitrages = useSelector(state => state?.redux.Arbitrages)
    const dispatch = useDispatch()
    const [opertinuties, setOpertinuties] = useState([])
    const [symbols, setSymbols] = useState({})
    const [marketdata, SetMarketdata] = useState([])
    const [chain , setChain]=useState('bnb')
    const [depthdata,setDepthdata]=useState({})
    var datas ={};
    var sockets;
    // var intervel;

    console.log('marketdata-->',marketdata)
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        getArbitage()
    }, []);

    useEffect(()=>{
        if(!isEmpty(symbols)){
            getDepth()
        }
    },[chain,symbols])

    // setInterval(() => {
    //     if(!isEmpty(symbols)){
    //         getDepth()
    //     }
    // }, 2000);

    const getArbitage = async () => {
        let sendData = {
            chain: chain , // optional
            pagesize: 100,
            pagenumber :1,
            from : 'orderbook'
        }
        let res = await getArbitrages(sendData)
        console.log("dispatch", res);
    
        setSymbols(res?.data[0])
        setOpertinuties(res?.data)
        // setInterval(() => {
        //     getDepth()
        // }, 5000);
        dispatch({
            type: "Arbitrages",
            data: { oppetinuties :res?.data }
        })
    }

    const getDepth = async () => {
console.log('symbols-->',symbols)
        let Marketdepths={}
       
            await Promise.all(symbols?.[(chain == 'bnb') ? 'bnbpairs' : 'kccpairs'].map(async (val) => {
                let data = await Getmarketdepth(val,chain)
                Marketdepths[val.toLowerCase()] = data.data
            }))
            SetMarketdata({...Marketdepths})
    }
   


    return (
        <div className={`${cascading.innerpages}`}>

            <Grid container >
                <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
                    <Sidebar />
                </Grid>
                <Grid item xl={10} lg={10} md={12} sm={12} xs={12} >
                    <div className={`${cascading.innercontent} ${cascading.apipage}`}>
                        <div className={`container ${cascading.custom_container}`}>
                          
                          
                          
                          
                          
                            <Row className='mb-4'>
                                <Col md={4}>
                                    
<Form.Select aria-label="Default select example" onChange={(e) => {setSymbols(JSON.parse(e.target.value));getDepth(JSON.parse(e.target.value))}}>
                                        {/* <option>Open this select menu</option> */}
                                        {
                                            opertinuties?.length > 0 && opertinuties?.map((val,i) => (
                                                <>
                                                {/* {console.log('fffffff-->',val)} */}
                                                <option id={i} value={JSON.stringify(val)}>{val.show}</option>
                                                </>
                                            ))
                                        }
                                        {/* <option value="1">Binance</option>
                                        <option value="2">Binance2</option>
                                        <option value="3">Binance3</option> */}
                                    </Form.Select>
                                    
                                    
                                </Col>
                                {/* <Col md={4}>
                                    <Form.Select aria-label="Default select example" onChange={(e) => {getDepth('',e.target.value)}}>
                                    <option id={'1'} value={'bnb'}>{'Binance'}</option>
                                    <option id={'2'} value={'kcc'}>{'Kucoin'}</option>
                                    </Form.Select>
                                </Col> */}
                            </Row>


                             
                     <Tab.Container id="left-tabs-example"  onSelect={(e)=>setChain(e)} defaultActiveKey="bnb">
                     <Row>
                     <Col sm={12}>
                     <Nav variant="pills" className="flex-row ms-3">
                                            <Nav.Item>
                                                <Nav.Link eventKey="bnb">Binance</Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="kcc">Kucoin</Nav.Link>
                                            </Nav.Item> */}
                                        </Nav>
                        </Col>
                        <Col sm={12}>
                        <Tab.Content>
                        <Tab.Pane eventKey="bnb">
                        <div className={`${cascading.tablelist} ${cascading.numtablelist} mt-0 pt-3 px-3 pb-3 mb-5`}>
                                {console.log('symbolsymbolsymbol-->1111',symbols)}
                                {(!isEmpty(marketdata) && !isEmpty(symbols) ) && <Orderbooktable symbol={symbols} data={marketdata} chain={chain}/>}
                            </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="kcc">
                            <div className={`${cascading.tablelist} ${cascading.numtablelist} mt-0 pt-3 px-3 pb-3 mb-5`}>
                                {console.log('symbolsymbolsymbol-->1111',symbols)}
                                {(!isEmpty(marketdata) && !isEmpty(symbols) ) && <Orderbooktable symbol={symbols} data={marketdata} chain={chain}/>}
                            </div>
                            </Tab.Pane>
                            </Tab.Content>
                            </Col>
                        </Row>
                     </Tab.Container>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Orderbook